import React, { FC, useMemo } from 'react'

/* mui */
import { Stack, Box, Button, useTheme, Snackbar } from '@mui/material'

/* icons */
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'

/* helpers */
import { getHasHighCheckPriority } from '../helpers/getHasHighCheckPriority'

/* component */
import { Copyright } from '../components/molecules/Copyright'
import { ErrordOverlay } from '../components/molecules/ErrordOverlay'
import {
  PatientMatchingBasicResultWindow,
  NonMatchingBasicResultWindow,
  MergedBasicResultWindow,
} from '../components/organisms/BasicResult'
import { MedicineResult } from '../components/organisms/MedicineResult'
import { QrImagesModalBody } from '../components/templates/QrImagesModalBody'
import { InitialScreenBody } from '../components/templates/InitialScreenBody'
import { RobotToast } from '../components/templates/RobotToast'
import { CustomFab } from '../components/molecules/CustomFab'

/* hooks */
import { usePrescriptions } from '../hooks/usePrescriptions'
import { useSelectedPresc } from '../hooks/useSelectedPresc'
import { useInccorectPrescLabelReportModal } from '../hooks/useInccorectPrescLabelReportModal'
import { useModal } from '../hooks/useModal'
import { usePrescriptionImageBox } from '../hooks/usePrescriptionImageBox'
import { useEditPresc } from '../hooks/useEditPresc'
import { useSoundNotification } from '../hooks/useMakeNotificationButton'
import { DrawerToggleButton } from '../hooks/useDrawerToggle'
import {
  useMedicationAlert,
  medicationAlertOpenButton,
} from '../hooks/useMedicationAlert'
import { useChatModalOpenButton } from '../hooks/useModalOpenButton'
import { useChatModal } from '../hooks/useChatModal'
import { useReportPresc } from '../hooks/useReport'
import { useUpdateNotifyModal } from '../hooks/useUpdateNotifyModal'
import { useSupportChatNotification } from '../hooks/useSupportChatNotification'
import useReactRouter from 'use-react-router'
import { useAnnotate } from '../hooks/useAnnotate'
import { localStorageWrapper } from '../helpers/localStorage'
import { useRobotToast } from '../hooks/useRobotToast'
import { useCamera } from '../hooks/useCameraModal'
import { SendToMobile } from '@mui/icons-material'

const IndexPage: FC = () => {
  const { history } = useReactRouter()
  const {
    AdditionalPrescSnackBar,
    prescriptions,
    loadInitialPrescriptions,
    LoadingOverlay,
  } = usePrescriptions()
  const { selectedPresc: userPresc, selectOcrRequest } = useSelectedPresc({
    prescriptions,
  })

  const isAnnotator = localStorageWrapper.getItem('is_annotator') || false
  const theme = useTheme()
  const {
    AnnotateDrawerButtons,
    selectedPresc: annotatePresc,
    loadAnnotatePrescList,
    SearchBox,
  } = useAnnotate()
  const selectedPresc = isAnnotator ? annotatePresc : userPresc
  const loadPresc = isAnnotator
    ? loadAnnotatePrescList
    : loadInitialPrescriptions

  const {
    editBasicResult,
    editRpnumberItem,
    editMedResult,
    updatingPrescription,
  } = useEditPresc({
    prescription: selectedPresc,
    refreshPrescriptions: loadPresc,
  })

  const {
    reportBasicResult,
    reportMedResult,
    openModal,
    renderSnackBar,
    ReportSendingOverlay,
  } = useReportPresc({
    prescription: selectedPresc,
    onSuccess: loadPresc,
  })

  // const { renderChatModal, ChatModalButton } = useChatModal(selectedPresc?.id)
  const { renderCameraModal, OpenButton, DuringOcrOverlay } = useCamera()

  const { MedicationModal, setMedicationAlertOpen } = useMedicationAlert(
    selectedPresc?.id,
    selectedPresc?.low_accuracy_alerts,
    selectedPresc?.reshape_reports,
    selectedPresc?.gigi_alerts,
    selectedPresc?.med_change_alerts
  )

  const { NotificationSoundButton, NotificationOverlay } = useSoundNotification(
    prescriptions
  )
  const { ChatNotificationSnackBars } = useSupportChatNotification()

  const DashboardNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/beta/dashboard')}
      >
        {'ダッシュボード'}
      </Button>
    </>
  )

  const SupportChatPageNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/support_chat')}
      >
        {'サポートチャット'}
      </Button>
    </>
  )
  const MedChangePageNavigateButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/med_change')}
      >
        {'採用薬の変更登録'}
      </Button>
    </>
  )

  const drawerButtomButtons = [
    // DashboardNavigateButton,
    // SupportChatPageNavigateButton,
    MedChangePageNavigateButton,
  ]
  const { renderModal: renderUpdateNotifyModal } = useUpdateNotifyModal()

  const prescImages = useMemo(() => selectedPresc?.presc_images || [], [
    selectedPresc,
  ])

  const prescImagesArea = useMemo(
    () => selectedPresc?.basic_result.処方箋存在領域.selected_option || [],
    [selectedPresc]
  )

  const {
    prescImageShown,
    PrescImageBox,
    PrescImageToggleButton,
  } = usePrescriptionImageBox(prescImages, prescImagesArea)

  const {
    openModal: openQrImageModal,
    renderModal: renderQrImageModal,
  } = useModal(
    selectedPresc && <QrImagesModalBody prescription={selectedPresc} />,
    'center'
  )

  const { FavoriteBox } = useRobotToast(selectedPresc, loadPresc)

  if (!selectedPresc) {
    return (
      <>
        {LoadingOverlay}
        {isAnnotator ? (
          AnnotateDrawerButtons
        ) : (
          <DrawerToggleButton
            PrescImageToggleButton={PrescImageToggleButton}
            prescriptions={prescriptions}
            selectOcrRequest={selectOcrRequest}
            BottomButtons={drawerButtomButtons}
          />
        )}
        <InitialScreenBody />
        {DuringOcrOverlay}
        {OpenButton}
        {renderCameraModal()}
        {ChatNotificationSnackBars}
      </>
    )
  }

  const userID: string = localStorageWrapper.getItem('userId') || ''
  const company = localStorageWrapper.getItem('app_type') || 'Medilab'
  const shouldMergeBasicResult = company === 'TMN' || company === 'TMN_BETA'

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      {DuringOcrOverlay}
      {ReportSendingOverlay}
      {NotificationOverlay}
      {isAnnotator ? (
        AnnotateDrawerButtons
      ) : (
        <DrawerToggleButton
          selectedPresc={selectedPresc}
          PrescImageToggleButton={PrescImageToggleButton}
          prescriptions={prescriptions}
          selectOcrRequest={selectOcrRequest}
          BottomButtons={drawerButtomButtons}
        />
      )}

      {PrescImageBox}
      <Box
        sx={{
          [theme.breakpoints.down('md')]: prescImageShown
            ? {
                display: 'none',
              }
            : {
                width: '100%',
              },
        }}
        width="50%"
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'scroll',
            height: '100vh',
          }}
        >
          {isAnnotator && SearchBox}
          {selectedPresc.was_error && ErrordOverlay}
          {!selectedPresc.was_error && MedicationModal}
          <Stack bgcolor={'primary.main'} p={2} spacing={1}>
            {medicationAlertOpenButton(() => setMedicationAlertOpen(true))}
            <CustomFab
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.primary.translucent,
                  color: '#fff',
                },
                bottom: 90,
              }}
              onClick={openModal}
            >
              <CampaignRoundedIcon style={{ fontSize: 35 }} />
            </CustomFab>
            {shouldMergeBasicResult ? (
              <MergedBasicResultWindow
                editBasicResult={editBasicResult}
                reportBasicResult={reportBasicResult}
                selectedPresc={selectedPresc}
                isCheckboxDisabled={updatingPrescription}
                openQrImageModal={openQrImageModal}
                getHasHighCheckPriority={getHasHighCheckPriority}
              />
            ) : (
              <>
                <PatientMatchingBasicResultWindow
                  editBasicResult={editBasicResult}
                  reportBasicResult={reportBasicResult}
                  selectedPresc={selectedPresc}
                  isCheckboxDisabled={updatingPrescription}
                  openQrImageModal={openQrImageModal}
                  getHasHighCheckPriority={getHasHighCheckPriority}
                />
                <NonMatchingBasicResultWindow
                  editBasicResult={editBasicResult}
                  reportBasicResult={reportBasicResult}
                  selectedPresc={selectedPresc}
                  isCheckboxDisabled={updatingPrescription}
                  openQrImageModal={openQrImageModal}
                  getHasHighCheckPriority={getHasHighCheckPriority}
                />
              </>
            )}
          </Stack>
          {renderSnackBar()}
          <Stack px={2} pb={20}>
            <MedicineResult
              prescription={selectedPresc}
              editMedResult={editMedResult}
              editRpnumberItem={editRpnumberItem}
              reportMedResult={reportMedResult}
            />
          </Stack>

          <Box p={2}>
            <Copyright />
          </Box>
        </div>
        {ChatNotificationSnackBars}
        {AdditionalPrescSnackBar()}
        {renderQrImageModal()}
        {OpenButton}
        {renderCameraModal()}
      </Box>
    </div>
  )
}

export default IndexPage
