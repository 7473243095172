import {
  Snackbar,
  Stack,
  IconButton,
  InputAdornment,
  Modal,
  CircularProgress,
  Typography,
  TextField,
  useTheme,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import { useCallback, useState } from 'react'
import {
  postBasicResultReport,
  postMedResultReport,
  postMedResultShortage,
} from '../apis/reportPresc'
import { postRequestMessage } from '../apis/requestMessages'
import { BasicResult, Prescription } from '../types/models/Presc'
import {
  CustomButton,
  CustomIconButton,
} from '../components/molecules/CustomButton'
import { localStorageWrapper } from '../helpers/localStorage'
import { SHORT_SERVICE_NAMES } from '../constants/const'

type Params = {
  prescription: Prescription
  onSuccess: () => Promise<void>
}

export const useReportPresc = ({ prescription, onSuccess }: Params) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isSnackAlertOpen, setIsSnackAlertOpen] = useState<boolean>(false)
  const [isSending, setIsSending] = useState(false)
  const theme = useTheme()
  const [inputError, setInputError] = useState<string>('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(event.target.value)
  }

  const handleSubmit = (event: React.MouseEvent) => {
    if (inputError) {
      reportAbstractError(inputError)
    }
    setInputError('')
  }

  const shortServiceName = (() => {
    const company = localStorageWrapper.getItem('app_type') || 'Medilab'
    const name =
      company === 'TMN' || company === 'TMN_BETA'
        ? SHORT_SERVICE_NAMES.TMN
        : SHORT_SERVICE_NAMES.DEFAULT
    return name
  })()

  const enableAbstractReport = (() => {
    const company = localStorageWrapper.getItem('app_type') || 'Medilab'
    const enable = company === 'Medilab'
    return enable
  })()

  const ReportSendingOverlay = isSending && (
    <Stack
      sx={{
        zIndex: 1305,
        position: 'absolute',
        bgcolor: 'rgb(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <CircularProgress style={{ width: '100px', height: '100px' }} />
      <Typography sx={{ color: '#fff', fontSize: '24px' }}>
        報告送信中...
      </Typography>
    </Stack>
  )
  const specificErrorTypes = enableAbstractReport
    ? [
        { type: 'med_shortage', displayLabel: '医薬品名に漏れがある' },
        {
          type: 'multiple_medical_speciality',
          displayLabel: '複数科の処方箋が分かれない',
        },
      ]
    : [
        {
          type: 'med_shortage',
          displayLabel: '薬剤に欠損がある',
        },
        {
          type: 'med_mistake',
          displayLabel: '薬剤に誤りがある',
        },
        {
          type: 'youhou_mistake',
          displayLabel: '用法に誤りがある',
        },
        {
          type: 'chouzai_youryou_mistake',
          displayLabel: '日数・用量に誤りがある',
        },
        {
          type: 'chouzai_youryou_shortage',
          displayLabel: '日数・用量が「0」になっている',
        },
      ]

  const abstractErrorTypes = enableAbstractReport
    ? [
        {
          type: '再来患者が新患と認識される',
          displayLabel: '再来患者が新患と認識される',
        },
        {
          type: '保険情報が別保険として認識される',
          displayLabel: '保険情報が別保険として認識される',
        },
        {
          type: '医師名がレセコンに認識されない',
          displayLabel: '医師名がレセコンに認識されない',
        },
        {
          type: '用法がうまくレセコンに入らない',
          displayLabel: '用法がうまくレセコンに入らない',
        },
      ]
    : []

  const renderSnackBar = () => (
    <div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MuiAlert
          elevation={10}
          variant="filled"
          iconMapping={{
            success: (
              <CampaignRoundedIcon style={{ fontSize: 40, color: 'black' }} />
            ),
          }}
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 600,
          }}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsModalOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              {'その他' + shortServiceName + 'への報告'}
            </Typography>
            <Stack>
              {specificErrorTypes.map((item) => (
                <Stack key={item.type} p={1}>
                  <CustomButton
                    variant="contained"
                    sx={{ width: 400, height: 50 }}
                    onClick={() => reportSpecificError(item.type)}
                  >
                    {item.displayLabel}
                  </CustomButton>
                </Stack>
              ))}
              {abstractErrorTypes.map((item) => (
                <Stack key={item.type} p={1}>
                  <CustomButton
                    variant="contained"
                    sx={{ width: 400, height: 50 }}
                    onClick={() => reportAbstractError(item.type)}
                  >
                    {item.displayLabel}
                  </CustomButton>
                </Stack>
              ))}
              {enableAbstractReport && (
                <Stack p={1}>
                  <TextField
                    label="その他"
                    variant="outlined"
                    value={inputError}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ flexGrow: 1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CustomIconButton onClick={handleSubmit} edge="end">
                            <SendIcon />
                          </CustomIconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </MuiAlert>
      </Modal>
      <Snackbar
        style={{
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        open={isSnackAlertOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackAlertOpen(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          iconMapping={{
            success: <CheckCircleIcon style={{ fontSize: 20 }} />,
          }}
          sx={{
            fontSize: '15px',
            padding: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 300,
            backgroundColor: theme.palette.success.main,
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => setIsSnackAlertOpen(false)}
            style={{ position: 'absolute', top: '0', right: '0' }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
          {shortServiceName + 'への報告が完了しました。'}
        </MuiAlert>
      </Snackbar>
    </div>
  )

  const reportBasicResult = useCallback(
    async (key: keyof BasicResult, isReported: boolean) => {
      try {
        setIsSending(true)
        await postBasicResultReport({
          prescId: prescription.id,
          key,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postBasicResultReport]
  )

  const reportMedResult = useCallback(
    async (rpNum: number, isReported: boolean) => {
      try {
        setIsSending(true)
        await postMedResultReport({
          prescId: prescription.id,
          rpNum,
          isReported,
        })
        await onSuccess()
        setIsSending(false)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, onSuccess, postMedResultReport]
  )

  const reportSpecificError = useCallback(
    async (ocrErrorLabel: string) => {
      try {
        setIsSending(true)
        await postMedResultShortage({
          prescId: prescription.id,
          ocrErrorLabel,
        })
        await onSuccess()
        setIsSending(false)
        setIsModalOpen(false)
        setIsSnackAlertOpen(true)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, postMedResultShortage]
  )

  const reportAbstractError = useCallback(
    async (ocrErrorLabel: string) => {
      try {
        setIsSending(true)
        await postRequestMessage(prescription.id, ocrErrorLabel)
        await onSuccess()
        setIsSending(false)
        setIsModalOpen(false)
        setIsSnackAlertOpen(true)
      } catch (e) {
        console.log(e)
      } finally {
        setIsSending(false)
      }
    },
    [prescription, postMedResultShortage]
  )

  const openModal = useCallback(() => {
    onSuccess()
    setIsSending(false)
    setIsModalOpen(true)
  }, [onSuccess, setIsModalOpen])
  return {
    reportBasicResult,
    reportMedResult,
    openModal,
    renderSnackBar,
    ReportSendingOverlay,
  }
}
