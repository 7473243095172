import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { getIppanMedicines, getSpecificMedicines } from '../apis/medicines'
import { MedChangeLog } from '../types/models/MedChangeLog'
import { IppanMedicine, SpecificMedicine } from '../types/models/Medicines'
import { DatePicker } from '../components/molecules/DatePicker'
import { useModal } from './useModal'
import { SearchableSelectBox } from '../components/organisms/SearchableSelectBox'
import {
  deleteMedChangeLog,
  patchMedChangeLog,
  postMedChangeLog,
} from '../apis/medChangeLog'
import dayjs, { Dayjs } from 'dayjs'

export const useMedChangeModal = (
  refreshMedLog: () => void,
  medChangeLog?: MedChangeLog
) => {
  const theme = useTheme()
  const [ippanMedicines, setIppanMedicines] = useState<IppanMedicine[]>([])
  const [ippanMedNames, setIppanMedNames] = useState<string[]>([])
  const [specificMedNames, setSpecificMedNames] = useState<string[]>([])
  const [ippanMed, setIppanMed] = useState<string | null>(null)
  const [changedFromMed, setChangedFromMed] = useState<string | null>(null)
  const [changedToMed, setChangedToMed] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [isScheduled, setIsScheduled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    fetchMedicine()
    medChangeLog && setIppanMed(medChangeLog.med_ippan_name)
    medChangeLog && setChangedFromMed(medChangeLog.original_med_name)
    medChangeLog && setChangedToMed(medChangeLog.updated_med_name)
    medChangeLog && setIsScheduled(medChangeLog.is_scheduled)
    const startDate = medChangeLog && dayjs(medChangeLog.start_date)
    startDate && setStartDate(startDate)
    const endDate = medChangeLog && dayjs(medChangeLog.end_date)
    endDate && setEndDate(endDate)
    console.log('dayjs', dayjs())
    console.log('dayjs__2', endDate)
  }, [medChangeLog])

  useEffect(() => {
    ippanMed && updateSpecificMedNames(ippanMed)
  }, [ippanMed])

  const sortInJaOrder = (
    medicines: IppanMedicine[] | SpecificMedicine[]
  ): string[] => {
    const sortedNames: string[] = medicines
      .map((item) => item.name)
      .sort((a, b) => a.localeCompare(b, 'ja'))
    return sortedNames
  }

  const fetchMedicine = async () => {
    try {
      const { data } = await getIppanMedicines()
      setIppanMedicines(data)
      setIppanMedNames(sortInJaOrder(data))
    } catch (e) {
      console.log(e)
    }
  }
  const updateSpecificMedNames = async (medName: string) => {
    const foundMedicine = ippanMedicines.find(
      (IppanMed) => IppanMed.name === medName
    )
    const ippanMedId = foundMedicine ? foundMedicine.id : null
    if (ippanMedId) {
      const { data } = await getSpecificMedicines(ippanMedId)
      setSpecificMedNames(sortInJaOrder(data))
    }
  }

  const isValidated =
    ippanMed && changedFromMed && changedToMed && startDate && endDate

  const isValidDateSpan = startDate && endDate && startDate <= endDate

  const isValidEndDate =
    startDate && endDate && dayjs() <= endDate.add(1, 'day')

  const submit = async () => {
    if (!ippanMed || !changedFromMed || !changedToMed || !startDate || !endDate)
      return
    medChangeLog
      ? await patchMedChangeLog(
          medChangeLog.id,
          ippanMed,
          changedFromMed,
          changedToMed,
          startDate,
          endDate,
          isScheduled
        )
      : await postMedChangeLog(
          ippanMed,
          changedFromMed,
          changedToMed,
          startDate,
          endDate,
          isScheduled
        )
    refreshMedLog()
    closeModal()
  }

  const MedChangeModalBody = () => (
    <Stack
      direction="column"
      spacing={5}
      p={2}
      sx={{ justifyContent: 'center', height: '100%' }}
    >
      <Stack direction="column" spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isScheduled}
              onChange={(e) => setIsScheduled(!isScheduled)}
              sx={{ height: 24 }}
              size="small"
              color="info"
            />
          }
          label={'期間内に在庫が切れ次第変更する'}
        />
        <Box>
          <SearchableSelectBox
            label="一般名"
            options={ippanMedNames}
            value={ippanMed || ''}
            setValue={setIppanMed}
          />
        </Box>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box width="50%">
            <SearchableSelectBox
              label="変更前"
              options={specificMedNames}
              value={changedFromMed || ''}
              setValue={setChangedFromMed}
            />
          </Box>
          <Box width="50%">
            <SearchableSelectBox
              label="変更後"
              options={specificMedNames}
              value={changedToMed || ''}
              setValue={setChangedToMed}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box width="50%">
            <DatePicker
              label="変更開始日"
              date={startDate}
              onChange={setStartDate}
            />
          </Box>
          <Box width="50%">
            <DatePicker
              label="アラート終了日"
              date={endDate}
              onChange={setEndDate}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        {medChangeLog && (
          <Button
            variant="contained"
            onClick={() => setIsOpen(true)}
            fullWidth
            sx={{ color: '#fff', bgcolor: theme.palette.secondary.dark }}
          >
            変更内容削除
          </Button>
        )}
        <Button
          variant="contained"
          disabled={!isValidated || !isValidDateSpan || !isValidEndDate}
          onClick={submit}
          fullWidth
          sx={{ color: '#fff', bgcolor: theme.palette.primary.dark }}
        >
          {isValidated
            ? isValidDateSpan
              ? isValidEndDate
                ? '変更登録'
                : 'アラート終了日は現在よりも後に設定してください'
              : 'アラート終了日は変更開始日よりも前の時刻を選択してください．'
            : '必要情報を記入してください'}
        </Button>
      </Stack>
      <Modal
        open={isOpen}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction="column"
          p={5}
          spacing={2}
          justifyContent="center"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
            padding: '8px',
            borderRadius: '8px',
            textAlign: 'center',
            height: '200px',
            width: '320px',
          }}
        >
          <Typography>薬剤変更内容を削除してよろしいですか？</Typography>
          <Stack direction="row" spacing={4} justifyContent="center">
            <Button
              variant="contained"
              onClick={async () => {
                medChangeLog && (await deleteMedChangeLog(medChangeLog.id))
                refreshMedLog()
                setIsOpen(false)
                closeModal()
              }}
            >
              はい
            </Button>
            <Button variant="contained" onClick={() => setIsOpen(false)}>
              いいえ
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  )

  const { openModal, closeModal, renderModal } = useModal(
    <MedChangeModalBody />,
    'center'
  )

  return {
    renderModal,
    openModal,
  }
}
