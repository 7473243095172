import React, { FC, memo, NamedExoticComponent } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material'
import { CloseIcon } from '../../icons/CloseIcon'
import { formatTimeInJP, formatTimeInJPFull } from '../../helpers'
import { Prescription } from '../../types/models/Presc'
import { localStorageWrapper } from '../../helpers/localStorage'
import { SERVICE_NAMES } from '../../constants/const'

type Props = {
  isOpen: boolean
  onClose: () => void
  prescriptions: Prescription[]
  selectedPresc?: Prescription
  selectOcrRequest: (ocrRequestEvent: string) => void
  BottomButtons: React.ReactNode[]
}

export const DrawerPrescList: FC<Props> = ({
  isOpen,
  onClose,
  prescriptions,
  selectedPresc,
  selectOcrRequest,
  BottomButtons,
}) => {
  const company = localStorageWrapper.getItem('app_type') || 'Medilab'
  const serviceName =
    company === 'TMN' || company === 'TMN_BETA'
      ? SERVICE_NAMES.TMN
      : SERVICE_NAMES.DEFAULT
  const theme = useTheme()

  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={onClose}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
      style={{
        opacity: 0.9,
        zIndex: 1301,
      }}
    >
      <Stack direction="column">
        <Box
          sx={{ height: '5vh' }}
          left={0}
          top={0}
          bgcolor={'primary.dark'}
          color="#fff"
          p={1}
        >
          <Button
            onClick={onClose}
            startIcon={<CloseIcon />}
            style={{ color: '#fff' }}
          >
            {selectedPresc ? selectedPresc.label : serviceName}
          </Button>
        </Box>
        <Box
          overflow="scroll"
          bgcolor={'primary.dark'}
          color="#fff"
          sx={{ width: 200, height: '85vh' }}
          role="presentation"
        >
          <List>
            {selectedPresc &&
              prescriptions.map((presc) => (
                <DrawerItem
                  key={presc.ocr_request_event_id}
                  prescription={presc}
                  selectOcrRequest={selectOcrRequest}
                  isSelected={presc.id === selectedPresc.id}
                />
              ))}
          </List>
        </Box>

        {BottomButtons.map((button, index) => (
          <Stack
            key={index}
            p={1}
            sx={{
              position: 'fixed',
              bottom: `${(BottomButtons.length - 1 - index) * 7}vh`,
              width: 184,
              height: '5vh',
              borderTop: '1px solid #fff',
              justifyContent: 'center',
            }}
            bgcolor={theme.palette.primary.dark}
          >
            {button}
          </Stack>
        ))}
      </Stack>
    </Drawer>
  )
}

type DrawerProps = {
  prescription: Prescription
  selectOcrRequest: (ocrRequestEvent: string) => void
  isSelected: boolean
}

const DrawerItem: NamedExoticComponent<DrawerProps> = memo(
  ({ prescription, selectOcrRequest, isSelected }) => {
    const isAnnotator = localStorageWrapper.getItem('is_annotator') || false
    const theme = useTheme()
    return (
      <ListItem
        button
        onClick={() => selectOcrRequest(prescription.ocr_request_event_id)}
        key={prescription.ocr_request_event_id}
        style={
          isSelected
            ? {
                backgroundColor: '#fff',
                color: theme.palette.primary.dark,
              }
            : { borderTop: '0.5px solid #fff' }
        }
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <ListItemText primary={prescription.label} />
          </Box>
          <Box>
            <ListItemText
              primary={
                isAnnotator
                  ? formatTimeInJPFull(prescription.request_event_created_at)
                  : formatTimeInJP(prescription.request_event_created_at)
              }
            />
          </Box>
        </Stack>
      </ListItem>
    )
  },
  (prev, next) => {
    return (
      prev.prescription.label === next.prescription.label &&
      prev.prescription.request_event_created_at ===
        next.prescription.request_event_created_at &&
      prev.isSelected === next.isSelected
    )
  }
)

DrawerItem.displayName = 'DrawerItem'
