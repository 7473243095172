import React, { FC } from 'react'
import { Stack, Modal, useTheme } from '@mui/material'
import {
  LowAccuracyAlert,
  GigiAlert,
  ReshapeReport,
} from '../../types/models/Presc'
import {
  GigiAlertTypography,
  ReshapeReportTypography,
  AccuracyAlertTypography,
  MedChangeTypography,
} from '../molecules/HighlightedTypography'
import { MedChangeAlert } from '../../types/models/MedChangeLog'
import { CustomButton } from '../molecules/CustomButton'
import { localStorageWrapper } from '../../helpers/localStorage'
import { SHORT_SERVICE_NAMES } from '../../constants/const'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  low_accuracy_alerts: LowAccuracyAlert[] | undefined
  reshape_reports: ReshapeReport[] | undefined
  gigi_alerts: GigiAlert[] | undefined
  med_change_alerts: MedChangeAlert[] | undefined
}

export const ShortConfirmationModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  reshape_reports,
  low_accuracy_alerts,
  gigi_alerts,
  med_change_alerts,
}) => {
  // short_alert_text が存在し、かつ空白のみでないアラートのみ抽出
  const validLowAccuracyAlerts =
    low_accuracy_alerts?.filter((item) => item.short_alert_text?.trim()) || []
  const validGigiAlerts =
    gigi_alerts?.filter((item) => item.short_alert_text?.trim()) || []

  // med_change_alerts や reshape_reports は今回フィルタリングの対象外として、そのまま利用
  const validMedChangeAlerts = med_change_alerts || []
  const validReshapeReports = reshape_reports || []

  // 表示すべきアラートが1件もなければ、モーダル自体をレンダリングしない
  if (
    validLowAccuracyAlerts.length === 0 &&
    validGigiAlerts.length === 0 &&
    validMedChangeAlerts.length === 0 &&
    validReshapeReports.length === 0
  ) {
    return null
  }

  // サービス名の取得
  const company = localStorageWrapper.getItem('app_type') || 'Medilab'

  const theme = useTheme()

  return (
    <Modal open={isOpen} hideBackdrop sx={{ pointerEvents: 'none' }}>
      <Stack
        position="fixed"
        direction="column"
        width={{ xs: '70%', md: '40%' }}
        maxHeight="70vh"
        borderRadius="8px"
        p={0}
        sx={{
          pointerEvents: 'auto',
          // 縦画面は画面中央、横画面は右半分の中央に表示
          top: '90%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '@media (orientation: landscape)': {
            left: '75%',
          },
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          boxShadow: theme.shadows[5],
          border: '1px solid #cccccc',
          overflowY: 'auto',
        }}
      >
        {/* コンテンツ全体 */}
        <Stack flex="1 1 auto" pt={2}>
          {/* AI精度診断セクション */}
          {validLowAccuracyAlerts.length > 0 && (
            <Stack pb={2}>
              <Stack pl={2} pr={2}>
                {validLowAccuracyAlerts.map((item) => (
                  <Stack key={item.id} pb={1}>
                    <AccuracyAlertTypography
                      text={item.short_alert_text}
                      highlightWord={item.target}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}

          {/* AIチェック結果セクション */}
          {(validGigiAlerts.length > 0 ||
            validMedChangeAlerts.length > 0 ||
            validReshapeReports.length > 0) && (
            <Stack pb={2}>
              <Stack pl={2} pr={2}>
                {validGigiAlerts.map((item) => (
                  <Stack key={item.id} pb={1}>
                    <GigiAlertTypography
                      text={item.short_alert_text}
                      medNames={item.medicine_names}
                    />
                  </Stack>
                ))}
                {validMedChangeAlerts.map((item) => (
                  <Stack key={item.id} pb={1}>
                    <MedChangeTypography
                      text={item.text}
                      medName={item.original_med_name}
                    />
                  </Stack>
                ))}
                {validReshapeReports.map((item) => (
                  <Stack key={item.id} pb={1}>
                    <ReshapeReportTypography
                      text={item.report_text}
                      highlightWords={item.targets}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  )
}
