import React, { useEffect, useState } from 'react'
import { MedicalConfirmationModal } from '../components/templates/MedicalConfirmationModal'
import { ShortConfirmationModal } from '../components/templates/ShortConfirmationModal'
import {
  GigiAlert,
  LowAccuracyAlert,
  ReshapeReport,
} from '../types/models/Presc'
import { CheckListIcon } from '../icons/CheckListIcon'
import { MedChangeAlert } from '../types/models/MedChangeLog'
import { CustomFab } from '../components/molecules/CustomFab'
import { localStorageWrapper } from '../helpers/localStorage'

export const useMedicationAlert = (
  id: string | undefined,
  low_accuracy_alerts: LowAccuracyAlert[] | undefined,
  reshape_reports: ReshapeReport[] | undefined,
  gigi_alerts: GigiAlert[] | undefined,
  med_change_alerts: MedChangeAlert[] | undefined
) => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isHoverOpen, setIsHoverOpen] = useState(false)

  useEffect(() => {
    if (
      !low_accuracy_alerts &&
      !gigi_alerts &&
      !reshape_reports &&
      !med_change_alerts
    )
      return
    const alertExists =
      !!low_accuracy_alerts?.length ||
      !!gigi_alerts?.length ||
      !!med_change_alerts?.length ||
      !!reshape_reports?.length
    setIsModalOpen(alertExists ? true : false)
  }, [id])

  const appType = localStorageWrapper.getItem('app_type') || 'Medilab'
  const isShowHover = appType === 'TMN_BETA'

  const MedicationHover = (
    <ShortConfirmationModal
      isOpen={isHoverOpen}
      setIsOpen={setIsHoverOpen}
      gigi_alerts={gigi_alerts}
      reshape_reports={reshape_reports}
      low_accuracy_alerts={low_accuracy_alerts}
      med_change_alerts={med_change_alerts}
    />
  )

  const MedicationModal = (
    <>
      <MedicalConfirmationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        setIsHoverOpen={setIsHoverOpen}
        gigi_alerts={gigi_alerts}
        reshape_reports={reshape_reports}
        low_accuracy_alerts={low_accuracy_alerts}
        med_change_alerts={med_change_alerts}
      />
      {isShowHover && MedicationHover}
    </>
  )

  const setMedicationAlertOpen = (value: boolean) => {
    setIsModalOpen(value)
  }

  return {
    MedicationModal,
    setMedicationAlertOpen,
  }
}

export const medicationAlertOpenButton = (onClick: () => void) => {
  return (
    <CustomFab onClick={onClick}>
      <CheckListIcon />
    </CustomFab>
  )
}
