import React, { FC } from 'react'
import { Typography } from '@mui/material'

type GigiAlertProps = {
  text: string
  medNames: string[]
}

type ReshapeReportProps = {
  text: string
  highlightWords: string[]
}

type AccuracyAlertProps = {
  text: string
  highlightWord: string
}

type MedChangeProps = {
  text: string
  medName: string
}

export const MedChangeTypography: FC<MedChangeProps> = ({ text, medName }) => (
  <Typography textAlign="left" sx={{ fontSize: 16 }}>
    {'☑︎ '}
    <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{medName}</span>{' '}
    {text}
  </Typography>
)

export const GigiAlertTypography: FC<GigiAlertProps> = ({ text, medNames }) => (
  <Typography textAlign="left" sx={{ fontSize: 16 }}>
    {'☑︎ '}
    {medNames.map((med, index) => (
      <React.Fragment key={index}>
        <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{med}</span>
        {index < medNames.length - 1 && ', '}
      </React.Fragment>
    ))}{' '}
    {text}
  </Typography>
)

export const ReshapeReportTypography: FC<ReshapeReportProps> = ({
  text,
  highlightWords,
}) => (
  <Typography textAlign="left" sx={{ fontSize: 16 }}>
    {'☑︎ '}
    {highlightWords.map((word, index) => (
      <React.Fragment key={index}>
        <span style={{ color: '#3885c7', fontWeight: 'bold' }}>{word}</span>
        {index < highlightWords.length - 1 && ', '}
      </React.Fragment>
    ))}{' '}
    {text}
  </Typography>
)

export const AccuracyAlertTypography: FC<AccuracyAlertProps> = ({
  text,
  highlightWord,
}) => (
  <Typography textAlign="left" sx={{ fontSize: 16 }}>
    {'☑︎ '}
    <span style={{ color: '#3885c7', fontWeight: 'bold' }}>
      {highlightWord}
    </span>{' '}
    {text}
  </Typography>
)
