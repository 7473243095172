import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  CircularProgress,
  Fab,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { CheckListIcon } from '../icons/CheckListIcon'
import { MedChangeAlert } from '../types/models/MedChangeLog'
import { CameraIcon } from '../icons/CameraIcon'
import { useModal } from './useModal'
import { useManualCamera } from './useManualCamera'
import { CameraModalBody } from '../components/templates/CameraModalBody'
import { createPrescription } from '../apis/prescription'
import { localStorageWrapper } from '../helpers/localStorage'

export const useCamera = () => {
  const theme = useTheme()
  const isActivatedPhoto =
    localStorageWrapper.getItem('is_activated_photo') || false
  const {
    CameraWithButton,
    images,
    resetImage,
    activateCamera,
  } = useManualCamera(1000, 1500)
  const [isSending, setIsSending] = useState(false)

  const SubmitButton = (
    <Button
      variant="contained"
      disabled={images.length === 0}
      onClick={() => submitImages(images)}
    >
      送信
    </Button>
  )

  const submitImages = async (images: string[]) => {
    setIsSending(true)
    resetImage()
    closeCameraModal()
    await createPrescription(images)
    setIsSending(() => false)
  }

  const {
    renderModal: renderCameraModal,
    openModal: openCameraModal,
    closeModal: closeCameraModal,
    isOpen: isCameraModalOpen,
  } = useModal(
    <CameraModalBody
      CameraComponent={CameraWithButton}
      SubmitButton={SubmitButton}
    />,
    'center'
  )

  useEffect(() => {
    window.requestAnimationFrame(activateCamera)
  }, [isCameraModalOpen])

  const OpenButton = isActivatedPhoto && (
    <Fab
      sx={{
        left: 20,
        bottom: 20,
        position: 'fixed',
        color: '#fff',
        backgroundColor: theme.palette.primary.dark, // theme を適切に定義してください
        zIndex: 1300,
      }}
      onClick={openCameraModal}
    >
      <CameraIcon />
    </Fab>
  )

  const DuringOcrOverlay = isSending && (
    <Stack
      sx={{
        zIndex: 1310,
        position: 'absolute',
        bgcolor: 'rgb(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <CircularProgress style={{ width: '100px', height: '100px' }} />
      <Typography sx={{ color: '#fff', fontSize: '24px' }}>
        処理実行中...
      </Typography>
    </Stack>
  )

  return { renderCameraModal, OpenButton, DuringOcrOverlay }
}
